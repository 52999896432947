import React from 'react'

import { SvgProps } from '../types'
import { Svg } from 'assets/svg-icons/styled'

export const UpWorkIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="64" height="64" viewBox="0 0 64 64" fill="none" {...props}>
      <g clipPath="url(#clip0_43_113)">
        <path
          d="M49.487 15.034C42.627 15.067 37.413 19.604 35.461 26.834C32.186 21.794 29.631 16.029 28.166 11.162L20.946 11.196L21.046 30.334C21.0503 31.235 20.877 32.128 20.5362 32.9621C20.1953 33.7961 19.6935 34.5548 19.0594 35.195C18.4254 35.8351 17.6714 36.344 16.8406 36.6928C16.0098 37.0415 15.1185 37.2232 14.2175 37.2275C12.3978 37.2361 10.6493 36.5215 9.35652 35.2409C8.7164 34.6068 8.20744 33.8529 7.8587 33.0221C7.50996 32.1913 7.32827 31.3 7.324 30.399L7.224 11.261L0 11.296L0.1 30.435C0.128 38.377 6.474 44.67 14.24 44.63C22.006 44.59 28.295 38.244 28.26 30.3L28.245 27.05C29.701 29.93 31.521 32.99 33.521 35.69L29.106 56.834L36.506 56.8L39.686 41.438C42.582 43.231 45.836 44.298 49.626 44.28C57.57 44.245 64.04 37.717 64 29.41C63.964 21.464 57.432 14.997 49.488 15.033L49.487 15.034ZM49.59 36.88C46.702 36.893 43.808 35.644 41.452 33.667L42.16 30.767V30.585C42.688 27.511 44.286 22.269 49.702 22.245C51.6158 22.2415 53.4533 22.9955 54.813 24.3423C56.1727 25.6891 56.9442 27.5192 56.959 29.433C56.796 33.586 53.382 36.853 49.589 36.87L49.59 36.88Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_43_113">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  )
}
