import React from 'react'

import { SvgProps } from '../types'
import { Svg } from 'assets/svg-icons/styled'

export const TelegramIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="50" height="50" viewBox="0 0 50 50" fill="none" {...props}>
      <path
        d="M46.137 6.55202C45.387 5.91602 44.209 5.82502 42.991 6.31402H42.989C41.708 6.82802 6.72795 21.832 5.30395 22.445C5.04495 22.535 2.78295 23.379 3.01595 25.259C3.22395 26.954 5.04195 27.656 5.26395 27.737L14.157 30.782C14.747 32.746 16.922 39.992 17.403 41.54C17.703 42.505 18.192 43.773 19.049 44.034C19.801 44.324 20.549 44.059 21.033 43.679L26.47 38.636L35.247 45.481L35.456 45.606C36.052 45.87 36.623 46.002 37.168 46.002C37.589 46.002 37.993 45.923 38.379 45.765C39.694 45.225 40.22 43.972 40.275 43.83L46.831 9.75302C47.231 7.93302 46.675 7.00702 46.137 6.55202ZM22 32L19 40L16 30L39 13L22 32Z"
        fill="black"
      />
    </Svg>
  )
}
