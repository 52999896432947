import React from 'react'

import { SvgProps } from '../types'
import { Svg } from 'assets/svg-icons/styled'

export const WorkHistoryIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="623" height="512" viewBox="0 0 623 512" fill="none" {...props}>
      <path
        d="M444.943 27.5371C437.99 28.8959 431.741 33.7834 428.482 40.356L426.689 43.9955L426.527 58.5533L426.363 73.1111H437.772H449.235V61.4325V49.7539H484.001H518.768V61.4325V73.1111H530.231H541.637L541.475 58.5533L541.313 43.9955L539.521 40.356C536.154 33.565 530.015 28.893 522.625 27.4804C519.04 26.8308 448.421 26.8308 444.943 27.5371Z"
        fill="black"
      />
      <path
        d="M362.753 85.6606C356.668 87.0733 349.934 92.6162 347.327 98.3179C345.047 103.262 344.882 105.923 345.047 131.076C345.208 154.544 345.208 154.924 346.405 157.151C347.112 158.399 348.47 160.084 349.503 160.844C350.808 161.823 367.865 167.743 405.837 180.4C435.823 190.396 460.431 198.597 460.485 198.597C460.593 198.597 460.647 191.264 460.647 182.301V166.004H484.007H507.367V182.301C507.367 191.264 507.421 198.597 507.529 198.597C507.583 198.597 532.191 190.394 562.177 180.4C600.149 167.743 617.206 161.82 618.511 160.844C619.543 160.084 620.902 158.399 621.608 157.151C622.803 154.924 622.803 154.544 622.967 131.076C623.075 116.246 622.913 106.088 622.533 104.187C620.902 95.277 613.734 87.618 605.096 85.6635C602.047 84.9543 365.686 84.9543 362.753 85.6606Z"
        fill="black"
      />
      <path
        d="M356.995 231.9L357.156 287.961L358.895 291.601C361.993 298.227 368.455 303.171 375.626 304.476C379.918 305.29 587.974 305.29 592.155 304.53C599.542 303.117 605.681 298.445 608.997 291.765L610.843 287.961L611.005 231.9L611.113 175.892L559.397 193.111L507.628 210.386L507.466 211.961C506.868 219.186 504.913 223.858 500.729 228.258C496.057 233.202 490.679 235.536 483.945 235.536C471.233 235.536 461.455 225.812 460.477 212.23L460.369 210.384L408.6 193.108L356.884 175.89L356.995 231.9Z"
        fill="black"
      />
      <path
        d="M472.595 196.317V214.678L474.008 217.558C474.93 219.404 476.234 220.925 477.647 221.849C479.548 223.098 480.419 223.316 484.004 223.316C487.697 223.316 488.404 223.154 490.415 221.742C491.828 220.763 493.186 219.189 494.054 217.45L495.413 214.678V196.317V177.955H484.004H472.595V196.317ZM486.446 207.073C492.04 209.734 490.194 218.156 484.001 218.156C477.917 218.156 475.908 209.791 481.394 207.127C483.621 205.987 484.22 205.987 486.446 207.073Z"
        fill="black"
      />
      <path
        d="M412.524 1.44577C427.634 5.67258 438.517 14.7636 445.429 28.8443L450.294 38.5728L450.675 223.837L450.931 409.101L462.969 409.613C473.209 410 476.031 410.637 483.324 414.221C521.606 433.171 521.606 487.331 483.199 506.276L473.471 511.14H295.506H117.41L109.087 507.169C98.5907 502.049 91.6732 496.03 86.4276 487.326C77.8486 472.984 78.3605 485.79 78.3605 265.697V66.2221H39.1803H-1.51068e-05L0.38138 49.8321C0.893404 35.1088 1.28005 32.9301 4.47759 26.2738C8.96041 17.0574 19.7129 7.19824 29.4466 3.22743L36.7455 0.280712L221.753 0.0246797C367.074 -0.0902645 408.046 0.165707 412.524 1.44577ZM91.1612 23.8495C92.5666 25.8976 94.7453 30.1244 96.0254 33.1966C98.0735 38.3168 98.2041 53.8081 98.8415 253.801L99.4789 468.898L102.295 474.149C110.743 490.027 130.336 495.79 145.572 486.824C155.687 480.936 160.551 472.357 160.551 460.45C160.551 443.934 147.489 429.848 132.128 429.848C127.901 429.848 125.984 429.08 123.037 426.134C118.554 421.651 118.298 417.173 122.139 412.56L125.085 409.231L277.7 409.357H430.45V228.058C430.45 94.1327 430.069 45.3545 428.914 41.7703C426.354 32.8099 417.263 24.3563 407.404 21.2842C405.612 20.7721 333.145 20.2601 246.336 20.2601L88.4704 20.1295L91.1612 23.8495ZM40.0737 21.42C30.4706 24.1107 22.5343 32.4285 21.1236 41.1382L20.3555 45.7464H49.2901H78.2247L77.07 42.0316C75.4033 36.1433 73.6112 33.4526 67.5976 28.2017C59.1492 20.7773 50.0634 18.4732 40.0737 21.42ZM175.661 437.142C182.96 451.609 182.96 467.743 175.786 482.979L171.946 491.302H318.677C426.354 491.302 466.684 490.915 470.268 489.766C481.407 486.563 491.909 472.613 491.909 461.087C491.909 449.049 484.354 437.273 473.471 432.278C468.476 429.974 461.563 429.843 320.088 429.843H172.082L175.661 437.142Z"
        fill="black"
      />
      <path
        d="M341.855 68.5702C348.386 72.029 349.666 79.9653 344.671 84.7042L341.725 87.5203H237.632C172.208 87.5203 132.772 87.0083 131.236 86.2403C125.091 82.9121 125.729 72.1544 132.516 68.7008C136.873 66.5221 337.884 66.3915 341.855 68.5702Z"
        fill="black"
      />
      <path
        d="M315.622 143.899C318.914 147.929 318.807 153.6 315.183 157.506L312.33 160.529L224.956 160.904C143.29 161.156 137.252 161.033 133.628 159.013C131.326 157.63 129.565 155.486 129.238 153.594C128.36 149.564 130.009 143.771 132.531 142.259C133.848 141.504 167.765 141 223.967 141H313.315L315.622 143.899Z"
        fill="black"
      />
      <path
        d="M315.228 217.126C317.001 219.003 318 221.624 318 224C318 226.376 317.001 228.997 315.228 230.874L312.569 234H222.365H132.161L129.611 230.874C125.952 226.499 126.174 220.752 130.054 216.876L133.048 214H222.808H312.569L315.228 217.126Z"
        fill="black"
      />
      <path
        d="M345.471 290.203C347.519 292.125 348.674 294.811 348.674 297.246C348.674 299.68 347.519 302.366 345.471 304.289L342.399 307.491H238.181H133.963L131.016 304.289C126.79 299.806 127.046 293.918 131.528 289.947L134.987 287H238.693H342.399L345.471 290.203Z"
        fill="black"
      />
    </Svg>
  )
}
