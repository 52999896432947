import React from 'react'

import { SvgProps } from '../types'
import { Svg } from 'assets/svg-icons/styled'

export const PortfolioIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enable-background="new 0 0 1000 1000"
      {...props}
    >
      <metadata>
        {' '}
        Svg Vector Icons : http://www.onlinewebfonts.com/icon{' '}
      </metadata>
      <g>
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
          <path d="M3623.3,5002.8c-245.1-47.9-465.4-220.2-580.3-451.9l-63.2-128.3l-5.7-513.2l-5.8-513.2h402.2h404.1v411.7v411.7h1225.6h1225.6v-411.7v-411.7h404.1h402.1l-5.7,513.2l-5.7,513.2l-63.2,128.3c-118.7,239.4-335.1,404.1-595.6,453.9C6235.4,5027.7,3745.9,5027.7,3623.3,5002.8z" />
          <path d="M725.9,2953.8C511.4,2904,274,2708.6,182.1,2507.6c-80.4-174.3-86.2-268.1-80.4-1154.8c5.7-827.3,5.7-840.7,47.9-919.2c24.9-44,72.8-103.4,109.2-130.2c46-34.5,647.3-243.2,1985.9-689.4c1057.1-352.4,1924.6-641.5,1926.5-641.5c3.8,0,5.7,258.5,5.7,574.5v574.5h823.5h823.5v-574.5c0-316,1.9-574.5,5.7-574.5c1.9,0,869.4,289.2,1926.5,641.5c1338.6,446.2,1939.9,655,1985.9,689.4c36.4,26.8,84.3,86.2,109.2,130.2c42.1,78.5,42.1,91.9,47.9,919.2c3.8,522.8-1.9,880.9-15.3,947.9c-57.5,314.1-310.2,584.1-614.7,653C9161.6,2978.7,829.3,2978.7,725.9,2953.8z" />
          <path d="M522.9-2201.5l5.7-1976.3l61.3-128.3c109.2-233.6,337-407.9,589.8-453.9c151.3-28.7,7485.8-28.7,7633.2-1.9c260.4,49.8,476.8,214.5,593.7,450l65.1,134.1l5.7,1976.3l3.8,1974.4l-1823.1-607l-1825-609l-5.7-55.5c-21.1-254.7-90-419.4-237.5-574.5c-164.7-174.3-354.3-256.6-591.7-256.6c-448.1,0-792.8,342.8-827.3,821.6l-3.8,65.1l-1825,609l-1823.1,607L522.9-2201.5z" />
          <path d="M4598.1-947.1v-647.3l49.8-101.5c32.5-65.1,78.5-118.7,128.3-151.3c67-44,97.7-51.7,224.1-51.7c130.2,0,155.1,5.7,226,55.5c49.8,34.5,97.7,90,128.3,151.3l47.9,97.7v647.3v647.3h-402.2h-402.2V-947.1z M5086.4-1326.3c197.2-93.8,132.1-390.7-86.2-390.7c-214.5,0-285.3,294.9-91.9,388.8C4986.8-1288,5007.9-1288,5086.4-1326.3z" />
        </g>
      </g>
    </Svg>
  )
}
